<template lang="pug">
    .search-view(v-if="tab.length")
        ._search
            ._search-icon
                svg-icon._search-friends-icon(icon-name="search")
            input._search-input(
                ref="searchInput",
                type="text",
                v-model="term",
                :placeholder="locale('full-name')"
            )
        ._ctrl
            ._ctrl-item(
                :class="{ 'search-view__ctrl-item_active': tab === 'all' }",
                @click="tab = 'all'"
            ) {{ locale("all") }}
            ._ctrl-item(
                :class="{ 'search-view__ctrl-item_active': tab === 'friends' }",
                @click="tab = 'friends'"
            ) {{ locale("friends") }}
            ._ctrl-item(
                :class="{ 'search-view__ctrl-item_active': tab === 'celebrities' }",
                @click="tab = 'celebrities'"
            ) {{ locale("celebrity") }}
            ._ctrl-item(
                v-if="isCelebrity"
                :class="{ 'search-view__ctrl-item_active': tab === 'fans' }",
                @click="tab = 'fans'"
            ) {{ locale("fans") }}
        friends-filter(v-if="pageWidth < 991")
        perfect-scrollbar.result(ref="scrollBar", infinite-wrapper)
            ._wrap
                router-link.person-item(
                    v-for="(item, index) in searchResults",
                    :key="index",
                    :to="{ name: 'person', params: { uuid: item.personId } }"
                )
                    .person-item-num {{ index + 1 }}
                    user-photo.person-item-photo(:photo="item.personAvatar", :micro="false")
                    .person-item-info
                        .person-item-info_name {{ item.personName }}
                            svg-icon.person-item-info__is-celebrity(
                                icon-name="star",
                                v-if="item.celebrity"
                            )
                        .person-item-leader-type
                            svg-icon._icon(
                                :icon-name="getLeaderTypeIcon(item.personLeaderType)",
                                addedCatalog="leader-types",
                                v-if="item.personLeaderType"
                            )
                            span.person-item-info_desc
                                span(v-if="item.personLeaderType === '_locked_' || item.personLeaderType === '_hidden_' || !item.personLeaderType") {{ locale("type-is-not-available") }}
                                span(v-else) {{ item.personLeaderType }}
            infinite-loading.loading(
                v-if="!stopFetch",
                :identifier="page",
                @infinite="infiniteHandler"
            )
                Loading(slot="spinner")
            span.no-result(v-else-if="term.length < 3 && stopFetch && !searchResults.length") {{ locale("limit") }}
            span.no-result(v-else-if="!searchResults.length && stopFetch") {{ locale("empty-filter") }}
</template>

<script>
import FriendsFilter from "../friends-filter/friends-filter.vue";
import UserPhoto from "@/components/ui/user-photo/user-photo.vue";
import InfiniteLoading from "vue-infinite-loading";
import SvgIcon from "../icon-base/icon-base";
import gql from "graphql-tag";
import personsSearchFullQuery from "@/graphql/queries/personsSearchFull.query.graphql";
import friendListQuery from "@/graphql/queries/friendList.query.graphql";
import celebritiesListQuery from "@/graphql/queries/celebritiesList.query.graphql";
import analyzedWithMePersonsListQuery from "@/graphql/queries/analyzedWithMePersonsList.query.graphql";

export default {
    name: "SearchPeople",
    components: { UserPhoto, InfiniteLoading, SvgIcon, FriendsFilter },
    data() {
        return {
            tab: "all",
            term: "",
            page: 1,
            itemsPerPage: 15,
            searchResults: [],
            searchTermMinLength: 3,
            searchTimerId: null,
            loading: false,
            stopFetch: false,
        };
    },
    computed: {
        categoryToSearch() {
            if (this.tab === "all") {
                return "personsSearchFull";
            }
            if (this.tab === "friends") {
                return "friendList";
            }
            if (this.tab === "celebrities") {
                return "celebritiesList";
            }
            if (this.tab === "fans") {
                return "analyzedWithMePersonsList";
            }
            return "personsSearchFull";
        },
        typeIcons: (state) => state.$store.getters["personTypeIcons"],
    },
    watch: {
        categoryToSearch() {
            this.searchResults = [];
            this.page = 0;
            this.stopFetch = false;
        },
        $route() {
            this.searchResults = [];
            this.page = 0;
            this.stopFetch = false;
        },
        term(term) {
            if (term.length > 0) {
                this.$router.replace({
                    params: {
                        ...this.$route.params,
                        name: this.term,
                    },
                });
            }
            if (term.length === 0) {
                this.$router.push("/search");
            }
            if (term.length >= this.searchTermMinLength || term.length === 0) {
                clearTimeout(this.searchTimerId);
                this.searchTimerId = setTimeout(() => {
                    this.searchResults = [];
                    this.page = 0;
                    this.stopFetch = false;
                    this.infiniteHandler();
                }, 1000);
            }
        },
    },
    created() {
        this.$store.dispatch("setPage", "search");
        this.$nextTick(() => {
            this.$refs.searchInput.focus();
        });
        let param = this.$route.params.name;
        if (param) {
            this.searchResults = [];
            this.term = param;
            this.page = 0;
            this.stopFetch = false;
        }
    },
    methods: {
        getLeaderTypeIcon(type) {
            return this.typeIcons[type];
        },
        infiniteHandler($state) {
            clearTimeout(this.searchTimerId);
            this.searchTimerId = setTimeout(() => {
                if (!this.stopFetch) {
                    this.page++;
                    this.personsSearch();
                    $state.loaded();
                }
            }, 500);
        },
        async personsSearch() {
            let queries = {
                personsSearchFull: personsSearchFullQuery,
                friendList: friendListQuery,
                celebritiesList: celebritiesListQuery,
                analyzedWithMePersonsList: analyzedWithMePersonsListQuery,
            };
            this.loading = true;
            this.$apollo
                .query({
                    query: gql`
                        ${queries[this.categoryToSearch]}
                    `,
                    variables: {
                        page: this.page,
                        itemsPerPage: this.itemsPerPage,
                        filter: {
                            gender: this.$route.query.gender,
                            age: this.$route.query.age,
                            sort: this.$route.query.sort || "DATE",
                            leaderType: this.$route.query.leader_type,
                            term: this.term,
                        },
                    },
                })
                .then((r) => {
                    let response = r.data[this.categoryToSearch].list;
                    if (this.categoryToSearch === "friendList") {
                        response = r.data[this.categoryToSearch];
                    }

                    if (response && response.length && this.page === 0) {
                        this.searchResults = response;
                    } else if (response.length && this.page > 0) {
                        this.searchResults =
                            this.searchResults.concat(response);
                    } else {
                        this.stopFetch = true;
                    }
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    this.stopFetch = true;
                    console.log(e);
                });
        },
    },
};
</script>

<style lang="scss">
@import "./search-people.scss";
</style>
