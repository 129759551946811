<template lang="pug">
search-people
</template>

<script>
import SearchPeople from "@/components/app/search-people/search-people.vue";

export default {
    components: { SearchPeople },
    mounted() {
        this.$store.dispatch("setPage", "search");
    },
};
</script>
